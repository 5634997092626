






























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ShuttleController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { ShuttleDetail } from '@/domain/entities/Shuttle'
import WarningCircleIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/shuttleManagement'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import {
  CourierSortFields,
  CourierSortType,
} from '@/app/infrastructures/misc/Constants/cancelValidation'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import { ManageCourier } from '@/domain/entities/ManageCourier'

@Component({
  components: {
    LoadingOverlay,
    DataTableV2,
    Button,
    WarningCircleIcon,
    AscendingIcon,
  },
})
export default class ShuttleManagementDetail extends Vue {
  controller = controller

  enumSelectedSort = CourierSortType
  enumSortFields = CourierSortFields

  selectedSort: CourierSortType | null = null

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Kurir ID', '100px'),
    this.headerCellMapper('Nama Kurir', '158px'),
    this.headerCellMapper('No. Handphone', '182px'),
    this.headerCellMapper('Jenis Kurir', '120px'),
    this.headerCellMapper('POS', '250px'),
    this.headerCellMapper('3LC', '80px'),
  ]

  created(): void {
    const shuttleId = this.$route.params.shuttleId

    this.controller.getShuttle(shuttleId)
  }

  get shuttleDetail(): ShuttleDetail {
    return this.controller.shuttleDetailData
  }

  get sortedCourierShuttleList(): ManageCourier[] {
    const tempCourierList = [
      ...(<ManageCourier[]>this.shuttleDetail.shuttleCourier),
    ]
    if (this.selectedSort) {
      if (
        this.selectedSort.split('-')[0] === this.enumSortFields.COURIER_NAME
      ) {
        if (this.selectedSort.split('-')[1] === 'asc') {
          return tempCourierList.sort(
            (a, b) => <number>a.fullName?.localeCompare(<string>b.fullName)
          )
        } else {
          return tempCourierList.sort(
            (a, b) => <number>b.fullName?.localeCompare(<string>a.fullName)
          )
        }
      } else {
        if (this.selectedSort.split('-')[1] === 'asc') {
          return tempCourierList.sort(
            (a, b) =>
              <number>a.phoneNumber?.localeCompare(<string>b.phoneNumber)
          )
        } else {
          return tempCourierList.sort(
            (a, b) =>
              <number>b.phoneNumber?.localeCompare(<string>a.phoneNumber)
          )
        }
      }
    }

    return tempCourierList
  }

  get shuttleCourierListData(): ITableCell[][] | [] {
    return this.sortedCourierShuttleList.length
      ? this.sortedCourierShuttleList.map((courier, index) => [
          this.tableCellMapper(index + 1, '60px'),
          this.tableCellMapper(<number>courier.courierId, '100px'),
          this.tableCellMapper(<string>courier.fullName || '-', '152px'),
          this.tableCellMapper(<string>courier.phoneNumber || '-', '182px'),
          this.tableCellMapper(
            <string>courier.courierTypeValid || '-',
            '120px'
          ),
          this.tableCellMapper(<string>courier.partnerName || '-', '250px'),
          this.tableCellMapper(<string>courier.origin || '-', '80px'),
        ])
      : []
  }

  public onTableSort(sortType: CourierSortFields): void {
    switch (sortType) {
      case CourierSortFields.COURIER_NAME:
        if (this.selectedSort === CourierSortType.COURIER_NAME_ASC) {
          this.selectedSort = CourierSortType.COURIER_NAME_DESC
        } else if (this.selectedSort === CourierSortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_NAME_ASC
        }
        break
      case CourierSortFields.COURIER_PHONE_NUMBER:
        if (this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_ASC) {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_DESC
        } else if (
          this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_ASC
        }
        break
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        wordBreak: 'break-word',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    isDefault = false,
    routes?: string
  ): ITableCell {
    return {
      value,
      isDefault,
      routes,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }
}
